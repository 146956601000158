const imagesPrefix = "www.google.com";

export default [
  {
    image: `${imagesPrefix}/about-us.jpg`,
    name: "Support Chatbot Demo",
    route: "/pages/landing-pages/sarah",
  },
  {
    image: `${imagesPrefix}/contact.jpg`,
    name: "Order Management Chatbot demo",
    route: "/pages/landing-pages/aida",
  },
  {
    image: `${imagesPrefix}/lexi.jpg`,
    name: "Content Generation Chatbot demo",
    route: "/pages/presentation/bots/lexi",
  },
  {
    image: `${imagesPrefix}/blog.jpg`,
    name: "Utility Chatbot demo",
    route: "/pages/presentation/bots/logi",
  },
];

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React components
import Box from "components/Box";
import Badge from "components/Badge";
import CustomTypography from "components/CustomTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/chatBotDemoData";

function ChatBotDemoBlock() {
  const renderData = data.map(({ image, name, route }) => (
    <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <Link to={route}>
        <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
      </Link>
    </Grid>
  ));

  return (
    <Box component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <Badge variant="contained" color="info" badgeContent="Check out our demos" container sx={{ mb: 2 }} />
          <CustomTypography variant="h2" fontWeight="bold">
            Start your AI journey with our chatbots.
          </CustomTypography>
          <CustomTypography variant="body1" color="text">
            Explore our demos to see how our chatbots can help you.
          </CustomTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <CustomTypography variant="h3" fontWeight="bold" mb={1}>
                Quick Demos of our most used chatbots.
              </CustomTypography>
              <CustomTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                These are just a few examples of our most used chatbots. We have many more to choose from.
              </CustomTypography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ChatBotDemoBlock;

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Bob from "pages/Presentation/components/Bots/Bob";
import Sarah from "pages/Presentation/components/Bots/Sarah";

const routes = [
  {
    name: "bots",
    icon: <Icon>child_care</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "support",
        collapse: [
          {
            name: "bob",
            route: "/bots/bob",
            component: <Bob />,
          },
          {
            name: "sarah",
            route: "/bots/sarah",
            component: <Sarah />,
          },
        ],
      },
    ],
  },
  {
    name: "docs",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "getting started",
        description: "All about overview, quick start, license and contents",
        href: "https://www.google.com",
      },
    ],
  },
  {
    name: "about us",
    icon: <Icon>people_outline</Icon>,
    collapse: [
      {
        name: "The team",
        route: "/pages/landing-pages/about-us",
        component: <AboutUs />,
      },
      {
        name: "Contact",
        route: "/pages/landing-pages/contact-us",
        component: <ContactUs />,
      },
    ],
  },
];

export default routes;

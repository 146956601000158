// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React components
import Box from "components/Box";

// React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <Box component="section" py={12}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid container justifyContent="flex-start">
            <Grid item xs={3} md={3}>
              <Box mb={2}>
                <DefaultInfoCard
                  icon="public"
                  title="Fully integrated"
                  description="We tailor our services to your business needs, from the initial consultation right down to the last detail."
                />
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <Box mb={2}>
                <DefaultInfoCard
                  icon="payments"
                  title="Flexible payments"
                  description="Fully flexible cost models to suit your business needs. We offer fixed price, time and materials and bespoke models."
                />
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <Box mb={2}>
                <DefaultInfoCard
                  icon="apps"
                  title="Prebuilt components"
                  description="Choose from a wide range of pre-built services and components to accelerate your business growth."
                />
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <Box mb={2}>
                <DefaultInfoCard
                  icon="3p"
                  title="Managed services"
                  description="Outsource your IT needs to us and enjoy the benefits of a fully managed IT service, including proactive monitoring, helpdesk support, and ongoing maintenance and upgrades."
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Information;

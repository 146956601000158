import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React components
import Box from "components/Box";

// React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Featuring() {
  return (
    <Box component="section" pt={3} pb={8}>
      <Container>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={2500}
              separator=","
              suffix="+"
              title="Hours"
              description="That meets quality standards required by our customers"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={24}
              suffix="/7"
              title="Support"
              description="Around the clock support to help you get up and running"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Featuring;

// @mui material components
import Grid from "@mui/material/Grid";

// React components
import Box from "components/Box";
import Input from "components/Input";
import Button from "components/Button";
import CustomTypography from "components/CustomTypography";

// React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/illustrations/contact-us.jpg";

function ContactUs() {
  return (
    <>
      <Box position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/contact-us",
            label: "start your ai journey now",
            color: "info",
          }}
        />
      </Box>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <Box
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={6} xl={4} ml={{ xs: "auto", lg: 6 }} mr={{ xs: "auto", lg: 6 }}>
          <Box
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <Box variant="gradient" bgColor="info" coloredShadow="info" borderRadius="lg" p={2} mx={2} mt={-3}>
              <CustomTypography variant="h3" color="white">
                Contact us
              </CustomTypography>
            </Box>
            <Box p={3}>
              <CustomTypography variant="body2" color="text" mb={3}>
                For further questions, pricing, or other assistance, please contact us using the form below.
              </CustomTypography>
              <Box width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Input variant="standard" label="Full Name" InputLabelProps={{ shrink: true }} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input type="email" variant="standard" label="Email" InputLabelProps={{ shrink: true }} fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      variant="standard"
                      label="What can we help you?"
                      placeholder="Describe your problem as detailed as possible."
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <Button type="submit" variant="gradient" color="info">
                    Send Message
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </Box>
    </>
  );
}

export default ContactUs;

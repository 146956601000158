import React from "react";
import { Box, Container, Typography } from "@mui/material";

const UnderConstruction = () => {
  return (
    <Box>
      <Container maxWidth="sm">
        <Typography variant="h4" align="center" gutterBottom>
          Under Construction
        </Typography>
        <Typography variant="body1" align="center">
          We apologize for the inconvenience, but our website is currently under construction. Please check back soon for
          updates.
        </Typography>
      </Container>
    </Box>
  );
};

export default UnderConstruction;

const imagesPrefix = "www.google.com";

export default [
  {
    title: "Suport",
    description: "Chatbots meant to help you with your customer support.",
    items: [
      {
        image: `${imagesPrefix}/bob.jpg`,
        name: "Bob",
        route: "/sections/page-sections/page-headers",
      },
      {
        image: `${imagesPrefix}/sarah.jpg`,
        name: "Sarah",
        route: "/sections/page-sections/features",
      },
    ],
  },
  {
    title: "Utility",
    description: "Chatbots meant to help enable you and your team to work better.",
    items: [
      {
        image: `${imagesPrefix}/logi.jpg`,
        name: "Logi",
        route: "/sections/page-sections/page-headers",
      },
    ],
  },
  {
    title: "Order Management",
    description: "Chatbots meant to collect information from your customers and issue orders in your system.",
    items: [
      {
        image: `${imagesPrefix}/aida.jpg`,
        name: "Aida",
        route: "/sections/page-sections/page-headers",
      },
    ],
  },
  {
    title: "Content Generation",
    description: "Chatbots meant to help you with creating content for your business or write contract drafts.",
    items: [
      {
        image: `${imagesPrefix}/Lexi.jpg`,
        name: "Lexi",
        route: "/sections/page-sections/page-headers",
      },
    ],
  },
];

import PropTypes from "prop-types";

// react-countup component
import CountUp from "react-countup";

// React components
import Box from "components/Box";
import CustomTypography from "components/CustomTypography";

function DefaultCounterCard({ color, count, title, description, ...rest }) {
  return (
    <Box p={2} textAlign="center" lineHeight={1}>
      <CustomTypography variant="h1" color={color} textGradient>
        <CountUp end={count} duration={1} {...rest} />
      </CustomTypography>
      {title && (
        <CustomTypography variant="h5" mt={2} mb={1}>
          {title}
        </CustomTypography>
      )}
      {description && (
        <CustomTypography variant="body2" color="text">
          {description}
        </CustomTypography>
      )}
    </Box>
  );
}

// Setting default props for the DefaultCounterCard
DefaultCounterCard.defaultProps = {
  color: "info",
  description: "",
  title: "",
};

// Typechecking props for the DefaultCounterCard
DefaultCounterCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "light", "dark"]),
  count: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DefaultCounterCard;

// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React components
import Box from "components/Box";
import Typography from "components/CustomTypography";
import SocialButton from "components/SocialButton";

// React examples
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Presentation page sections
import ChatBotDemoBlock from "pages/Presentation/sections/ChatBotDemoBlock";
import ChatBotsBlock from "pages/Presentation/sections/ChatBotsBlock";
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
// import Testimonials from "pages/Presentation/sections/Testimonials";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "mailto:a@b.com",
          label: "start your AI journey now",
          color: "info",
        }}
        sticky
      />
      <Box
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <Typography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              CTRL - AI - DELETE{" "}
            </Typography>
            <Typography variant="body1" color="white" textAlign="center" px={{ xs: 6, lg: 12 }} mt={1}>
              Revolutionize Your Business: Innovative AI, Custom Chatbots & On-Demand Solutions for Enhanced Consumer
              Engagement
            </Typography>
          </Grid>
        </Container>
      </Box>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <ChatBotsBlock />
        <ChatBotDemoBlock />
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Book a free consultation with our team to discuss your business needs and how we can help you."
                action={{
                  type: "internal",
                  route: "/documentation/quick-start",
                  label: "Let's start",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        {/* <Testimonials /> */}
        <Box pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <SocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=www.ctrolaidelete.com"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </SocialButton>
                <SocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.ctrlaidelete.com"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </SocialButton>
                <SocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.ctrlaidelete.com"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </SocialButton>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Card>
      <Box pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </Box>
    </>
  );
}

export default Presentation;
